import { Formik, Form, Field } from 'formik';
import Button from 'components/Button/Button';
import { FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import * as Yup from 'yup';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CreateMroCraftDocument, GetMroCraftsDocument, LookupTailDocument } from 'graphql/generated';
import { useSession } from 'contexts';

const NewCraftsSchema = Yup.object().shape({
  tailNumber: Yup.string().required('Required'),
  make: Yup.string().required('Required'),
  model: Yup.string().required('Required'),
  year: Yup.number().required('Required'),
  engineCount: Yup.number().required('Required'),
});


const NewCraft = function ({ closeFlyout }: { closeFlyout: () => void}){
  const { user } = useSession();
  const [createCraft] = useMutation(CreateMroCraftDocument, {
    refetchQueries: [{ query: GetMroCraftsDocument, variables: { where: { mroOrganizationId: { equals: user.mroOrganizationId } } } }],
  });
  const [getTailInfo] = useLazyQuery(LookupTailDocument);
  const initialValues = { tailNumber: '', make: '', model: '', ownerName: '', year: 2000, engineCount: 1 };
  const handleSubmit = ({ ...values }: typeof initialValues) => {
    createCraft({
      variables: {
        input: {
          mroOrganization: { connect: { id: user.mroOrganizationId } },
          ...values,
        },
      },
    }).then(() => closeFlyout()).catch(console.error);
  };

  return (
    <div className="p-4">
      <Formik initialValues={initialValues} validationSchema={NewCraftsSchema} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
              <FullFormikInput name="tailNumber" label="Tail Number" onBlur={async () => {
                var tempTail = values.tailNumber.toUpperCase();
                if (tempTail.startsWith('N')) {
                  tempTail = tempTail.substring(1);
                }
                const tailInfo = await getTailInfo({ variables: { tailNumber: tempTail } });
                const registration = tailInfo?.data?.registrations?.[0];
                if (registration) {
                  setFieldValue('make', registration?.manufacturer);
                  setFieldValue('model', registration?.model);
                  setFieldValue('year', registration?.yearMfr);
                  setFieldValue('engineCount', registration?.engineCount);
                }
              }} />
              <FullFormikInput name="make" label="Make" />
              <FullFormikInput name="model" label="Model" />
              <FullFormikInput name="year" label="Year" />
              <FullFormikInput name="engineCount" label="Engine Count" />
            </div>
            <div className="flex justify-end items-center mt-4">
              <Button text="Submit" color="navy" size="sm" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewCraft;