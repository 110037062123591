import { useQuery } from '@apollo/client';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import { Table } from 'components/Table/Table';
import Tabs from 'components/Tabs/Tabs';
import { useSession } from 'contexts';
import { GetInspectionFreeLimitDocument, GetTransactionsDocument, GetUserWalletDocument, StripePayBalanceDocument } from 'graphql/generated';
import useParamState from 'hooks/useParamState';
import { useContext, useMemo, useState } from 'react';
import { formatApiDate, formatUSD } from 'utils/formatter';
import Admin from './partials/Admin';
import Card from 'components/Card/Card';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import ViewTransaction from './Flyouts/ViewTransaction';
import { AbilityContext } from 'contexts/AbilityContext';
import { permissionHandler } from 'utils/PermissionHandler';
import { OrgSubtypes } from 'utils/orgTypes';
import Button from 'components/Button/Button';
import ScreenShot from 'assets/images/payments.jpg';
import { useNavigate } from 'react-router-dom';
import isMobileApp from 'utils/isMobileApp';
import Modal from 'components/Modal/Modal';

const Payments = function() {
  const [activeTab, setActiveTab] = useParamState('requestsTab', 'My Transactions');
  const [paymentId, setPaymentId] = useParamState('paymentId', '');
  const { user, organizationSubtype } = useSession();
  const ability = useContext(AbilityContext);
  const tabs = [ 'My Transactions', 
   ...(permissionHandler('Payment Admin', ability) ? ['Admin'] : [])
  ];
  const [ showModal, setShowModal ] = useState(false);
  const [ query, setQuery ] = useState('');
  const [ limit, setLimit ] = useState(10);
  const [ skip, setSkip ] = useState(0);
  
  const { data : { transactions, transactionCount } = {} } = useQuery( GetTransactionsDocument, { variables: { 
    where: { 
      wallet: { is: { userProfileId: { equals: user?.userOrganizationProfileId } } } ,
      ...( query && { OR:[
        { inputData: { path:[ 'description' ], string_contains: query  } },
        { wallet: { is: { userProfile: { is: { user: { is: { firstName: { contains: query }  } } } } } } },
        { wallet: { is: { userProfile: { is: { user: { is: { lastName: { contains: query }  } } } } } } },
        { value: { equals: Number(query) } },
      ] } )

  } } } );
  let hasPendingTransactions = false;
  transactions?.forEach((transaction: any) => {
    if(transaction.status === 'PENDING'){
      hasPendingTransactions = true;
    }
  });

  const { data : { userWallet } = {} } = useQuery( GetUserWalletDocument, { variables: { userProfileId: user?.userOrganizationProfileId } } );
  const { data : { stripePayBalance } = {}, loading } = useQuery( StripePayBalanceDocument, { variables: { id: user?.userOrganizationProfileId } });
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        className: 'text-left w-4/12',
        accessor: 'timestamp',
        Cell: ({ value }: any) => {
          return (
            <div className="w-full text-left">
              <span>{formatApiDate(value)}</span>
            </div>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'inputData.description',
        className: 'text-center w-6/12',
      },
      {
        Header: 'Amount',
        accessor: (row: any) => row?.value,
        className: 'text-center w-2/12',
        Cell: ({ row, value }: any) => {
          return (
            <div className="w-full text-left">
              <span>{formatUSD(value) ?? 'Unknown'}</span>
            </div>
          );
        }
      },
    ],
    []
  );
  
  return(
    <>
      { showModal && <Modal message="You have pending transactions which may affect how much you owe." cancel={setShowModal} accept={async () => { 
        stripePayBalance && window.open(stripePayBalance, '_self');
      }} />}  
      <Flyout {...flyoutProps} />
      <div className="flex flex-col md:px-8">
        {/* Header */}
        <div className="flex justify-between items-center bg-white p-6 border-x border-t border-slate-300 rounded-t shadow-blue flex-wrap gap-4">
          <div className="flex items-center">
            <CurrencyDollarIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
            <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">Payments</h1>
          </div>
          { organizationSubtype === OrgSubtypes.MULTI && <div className="relative flex items-center">
              <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
              <input
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7"
                placeholder="Search"
              />
          </div> }
        </div>
        { organizationSubtype !== OrgSubtypes.MULTI ? 
          <div>
            {isMobileApp() ?  
              <Card>
                <h1 className="text-center text-2xl font-bold text-brand-dark">This Feature is not available for your organization</h1>
              </Card>
            :
            
            <Card>
              <h1 className="text-center text-3xl font-bold text-brand-dark">Try Our Multi-Pilot Plan</h1>
              <h2 className="text-center text-xl font-semibold text-slate-400">Aircraft financial management is available on our multi aircraft plan</h2>
              <div className='flex flex-col justify-center my-8 gap-7'>
                <img className="max-h-[26rem] mx-auto" src={ScreenShot} alt="" />
                <Button text="UPGRADE NOW" color="navy" size="sm" onClick={ () => navigate('/account/settings?settingsTab=Billing') } />
              </div>
            </Card>}
          </div>
          :
          <>
            <div className="flex justify-between items-center border border-slate-200 bg-slate-50 w-full px-4">
              <Tabs setActiveItem={setActiveTab} activeItem={activeTab} items={tabs} className='-mr-4' />
            </div> 
            { activeTab === 'My Transactions' && <div className='flex flex-col lg:flex-row gap-5'>
              <Card title='Transactions' className='lg:w-2/3 order-last lg:order-first'>
                <Table
                columns={columns}
                skip={skip}
                limit={limit}
                updateLimitAndSkip={(lim, sk) => { setLimit(lim); setSkip(sk); }}
                count={transactionCount}
                data={transactions ?? []}
                onRowClick={(row) => { 
                  updateFlyout({ title: 'Transaction Details', content: <ViewTransaction id={row?.id} closeFlyout={closeFlyout} /> });
                }}
                /> 
              </Card>
              <Card className='lg:w-1/3 flex flex-col justify-center gap-4 h-[290px]'>
                <h1 className='text-center text-2xl text-brand-dark font-bold'>You owe</h1>
                <h2 className={`text-center text-lg text-brand-dark font-bold ${Number(userWallet?.balanceCache) <= 0 ? 'text-emerald-500' : 'text-red-500' }`}>{formatUSD(userWallet?.balanceCache)}</h2>
                { Number(userWallet?.balanceCache) >= 0.5 && stripePayBalance !== undefined && stripePayBalance !== 'stripe not setup for this organization' && 
                  <button onClick={() =>{
                    if(hasPendingTransactions){
                      setShowModal(true);
                    }else{
                      stripePayBalance && window.open(stripePayBalance, '_self');
                    }
                  }} className='bg-brand-pale text-brand-electric text-center font-bold rounded-md p-2 mx-8'>Pay Balance</button> }
                { loading && <h3 className='text-sm text-center' >Loading Payment Link...</h3> }
                {hasPendingTransactions && <h3 className='text-xs font-bold text-center' >You have pending transactions which may affect how much you owe.</h3>}
                <h3 className='text-xs text-center' >A negative balance indicates unspent funds.</h3>
                { Number(userWallet?.balanceCache) < 0.5 &&  Number(userWallet?.balanceCache) > 0 && <h3 className='text-xs text-center'>(Cannot make payments less than $0.50)</h3> }
              </Card>
            </ div> }
            { activeTab === 'Admin' && <Admin query={query} paymentId={paymentId} setPaymentId={setPaymentId} /> } 
          </>
        }
      </div>
    </>
  );
}

export default Payments;