import { Menu, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/24/solid";
import Flyout, { useFlyout } from "components/Flyout/Flyout";
import Paywall from "components/Paywall/Paywall";
import SegmentControl from "components/SegmentControl/SegmentControl";
import { useSession } from "contexts";
import useParamState from "hooks/useParamState";
import { Fragment, useState } from "react";
import { OrgSubtypes } from "utils/orgTypes";
import New from "./New/index";
import { useQuery } from "@apollo/client";
import { GetAnnouncementsDocument } from "graphql/generated";
import AnnouncementEntry from "./Components/AnnouncementEntry";

const Announcements = function() {
  const { flyoutProps, closeFlyout, updateFlyout } = useFlyout();
  const { organizationSubtype, organizationId, user } = useSession();
  const [ query, setQuery ] = useState('');
  const { data: { announcements } = {}, refetch } = useQuery(GetAnnouncementsDocument, {
    variables: {
      where: {
        organizationId: { equals: organizationId },
        OR: [
          { active: { equals: true } },
          { active: { equals: false }, creatorId: { equals: user.userOrganizationProfileId } }
        ]
      }
    }
  });
  return(
    <>
      <Flyout {...flyoutProps} />
      {organizationSubtype !== OrgSubtypes.MULTI ? <Paywall /> : 
        <div className="flex flex-col">
          {/* Title */}
          <div className="flex flex-col md:flex-row justify-between items-center bg-white p-6 border border-slate-300 rounded mb-2 shadow-blue">
            <div className="flex items-center mb-5 md:mb-0">
              <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">Announcements</h1>
            </div>
            <div className="flex justify-end gap-2 flex-wrap">
              <div className="relative flex items-center">
                <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
                <input
                  className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7"
                  placeholder="Search"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              <button
                onClick={()=> updateFlyout({ title: 'New Announcements', content: <New closeFlyout={()=>{closeFlyout();refetch();}} /> })}
                className="flex items-center bg-brand text-white border border-brand text-sm text-wrap rounded font-semibold py-1.5 px-4">
                <PlusIcon className="h-3 w-3 mr-1" />
                Add New Announcement
              </button>
            </div>
          </div>
          {/* Content */}
          <div className="grid md:grid-cols-2 gap-4 grid-cols-1">
            { [...(announcements ??[])]?.sort( (a, b) => Number(b.pinned) - Number(a.pinned) )?.map((announcement) => (
              <AnnouncementEntry key={announcement.id} {...announcement} />
            ))}
          </div>
        </div>
      }
    </>
  );
}

export default Announcements;