import { PencilIcon } from "@heroicons/react/24/solid";
import { TrashIcon } from "assets/icons";
import Flyout, { FlyoutProps, useFlyout } from "components/Flyout/Flyout";
import { GetCraftsReservationWithExportQuery, GetOrganizationSettingsDocument, GetReservationDocument, GetReservationsQuery, RemoveReservationDocument } from "graphql/generated";
import { applyTimezone, formatApiDate, formatApiDateTimeUTC, formatApiDateUTC } from "utils/formatter";
import Edit from "../Edit";
import Button from "components/Button/Button";
import { useState } from "react";
import Card from "components/Card/Card";
import { useMutation, useQuery } from "@apollo/client";
import Modal from "components/Modal/Modal";
import { useSession } from "contexts";

const View = function({ id, refreshData, closeView }: {id: string, refreshData?:()=>void, closeView?:()=>void }) {
  const [ modal, setModal ] = useState(false);
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const { organizationId, user } = useSession();
  const { data:{ reservation } = { reservation: undefined }, refetch }  = useQuery(GetReservationDocument, { variables: { id } });
  const [ deleteReservation ] =  useMutation( RemoveReservationDocument, { variables: { id: id }, onCompleted: ()=>{ refreshData(); closeView(); } });
  const { data : { organization } = { } } = useQuery(GetOrganizationSettingsDocument, { variables: { id: organizationId } });
  const canEdit = organization?.reservationSettings?.allowForEditAfter || new Date(reservation?.from) > new Date();
  return(
    <>
      {/* MODAL */}
      {modal && <Modal cancel={setModal} accept={deleteReservation} message={'Are you sure you want to delete this Reservation?'}/> }
      <Flyout {...flyoutProps} />
      <div className="overflow-hidden sm:rounded-lg">
        <Card className="m-4">
          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">From</dt>
            <dd className="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2">{reservation?.allDay ? 
              formatApiDateUTC(applyTimezone(reservation?.from, Number(reservation?.timezone), Number(user.timezone))) :
              formatApiDateTimeUTC(applyTimezone(reservation?.from, Number(reservation?.timezone), Number(user.timezone)))
            }</dd>
          </div>
          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">To</dt>
            <dd className="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2">{reservation?.allDay ? 
              formatApiDateUTC(applyTimezone(reservation?.to, Number(reservation?.timezone), Number(user.timezone))) :
              formatApiDateTimeUTC(applyTimezone(reservation?.to, Number(reservation?.timezone), Number(user.timezone)))}</dd>
          </div>
          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Date Created</dt>
            <dd className="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2">{formatApiDate(reservation?.createdAt)}</dd>
          </div>
          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Pilot</dt>
            <dd className="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2">{(reservation?.pilot?.user?.firstName ?? '-') + ' ' + (reservation?.pilot?.user?.lastName ?? '-')}</dd>
          </div>
          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Craft</dt>
            <dd className="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2">{(reservation?.craft?.craftName ?? '-')}</dd>
          </div>
          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">For Maintenance?</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{reservation?.isMaintenance ? 'Yes' : 'No'}</dd>
          </div>
          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Notes</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{reservation?.notes}</dd>
          </div>
        </Card>
        <div className="bg-gray-50 px-4 pb-8 flex items-end self-start justify-end gap-10">
          <Button text="Delete" color="red" size="sm" icon={ <TrashIcon className="h-3 w-3 mr-1" /> } onClick={ () => setModal(true) } />
          <div className="flex flex-col self-start gap-1">
            <Button text="Edit" color="navy" size="sm" disabled={!canEdit} icon={ <PencilIcon className="h-3 w-3 mr-1" /> } onClick={ () => updateFlyout({ title: 'Edit Reservation', content: <Edit closeFlyout={closeFlyout} id={id} /> }) } />
            { !canEdit && <h3 className="-mb-7">Cannot Edit Past Start Date</h3> }
          </div>
        </div>
      </div>
    </>
  );
}

export default View;