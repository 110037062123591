import { useMutation, useQuery } from '@apollo/client';
import {
  GetCraftDocument,
  GetComponentsDocument,
  AnnouncementCreateInput,
  CreateAnnouncementDocument,
  UpdateAnnouncementDocument,
  AnnouncementUpdateInput,
  GetAnnouncementDocument,
  GetAnnouncementQuery,
} from 'graphql/generated';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useSession } from 'contexts';
import { useEffect, useState } from 'react';
import Card from 'components/Card/Card';
import FilePicker from 'components/FilePicker/FilePicker';
import { FullFormikCheckBox, FullFormikInput } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import ReactQuill from 'react-quill';


const Edit = function ({ closeFlyout, announcement }: { closeFlyout: () => void, announcement: GetAnnouncementQuery['announcement'] }) {
  const { user, organizationId } = useSession();
  const [ updateAnnouncement ] = useMutation(UpdateAnnouncementDocument, { refetchQueries: ['GetAnnouncementDocument', 'GetAnnouncementsDocument'] });
  const [prefill, setPrefill] = useState({
    title: '',
    content: '',
    active: true,
    pinned: false,
  });
  const [files, setFiles] = useState<File[]>([]);
  useEffect(() => {
    if (announcement) {
      setPrefill({
        title: announcement.title,
        content: announcement.content,
        active: announcement.active,
        pinned: announcement.pinned,
      });
    }
  }, [announcement]);

  const handleSubmit = async (values: typeof prefill, { setSubmitting }: FormikHelpers<typeof prefill>) => {
    const announcementsInput: AnnouncementUpdateInput = {
      title: values.title,
      content: values.content,
      active: values.active,
      pinned: values.pinned,
      organization: { connect: { id: organizationId } },
      creator: { connect: { id: user.userOrganizationProfileId } },
      id: announcement.id,
    };
    await updateAnnouncement({ variables: { input: announcementsInput } });
    setSubmitting(false);
    setPrefill({  
      title: '',
      content: '',
      active: true,
      pinned: false});
    closeFlyout();
  };

  return (
    <Formik enableReinitialize initialValues={prefill} onSubmit={handleSubmit}>
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <Card className='m-4'>
            <dl>
              <div className="p-4 flex items-center justify-center w-full">
                <FullFormikInput name='title' label='Title' isVertical={true} className='w-full' />
              </div>
              <div className="p-4 flex items-center justify-around w-full">
                <FullFormikCheckBox name='active' label='Active' opposite={true} isVertical={true} className='' />
                <FullFormikCheckBox name='pinned' label='Pinned' opposite={true} isVertical={true} className='' />
              </div>
              <div className="pb-20 mt-2">
                <dt className="mb-2">Content</dt>
                <ReactQuill className='h-64 mb-5' theme="snow" value={values.content} onChange={(value) => setFieldValue('content', value)} />
              </div>
            </dl>
          </Card>
          <div className="px-4 mb-10 flex items-center gap-10 justify-end">
            <Button
              size='xs'
              color='pale'
              onClick={closeFlyout}
              disabled={isSubmitting}
              text='Close' />
            <Button
              type="submit"
              size='xs'
              disabled={isSubmitting}
              text='Submit' />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Edit;
