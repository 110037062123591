import { Formik, Form } from 'formik';
import { FullFormikCheckBox, FullFormikInput, FullFormikSelect, FullFormikTextarea } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { useMutation, useQuery } from '@apollo/client';
import { CraftType, CreateCraftDocument, GetCraftDocument, GetCraftsDocument, GetMroPartInventoriesDocument, LogType, PartCondition, RemoveCraftDocument, UpdateCraftDocument, VolumeMeasurement } from 'graphql/generated';
import { useCallback, useEffect, useState } from 'react';
import { useSession } from 'contexts';import { useNavigate } from 'react-router-dom';
import Modal from 'components/Modal/Modal';

const ShareAircraft = function ({ closeFlyout }: { closeFlyout: () => void }) {
  const [initialValues, setInitialValues] = useState({ 
    sharePlane: false,
    engineTimes: true,
    fuelStatus:true, 
    currentLocation: true, 
    planeAirworthiness: true,
    scheduleStatus: true,
    inspections: true,
  });
  const { user, craftId, setCraftId, organizationId } = useSession();
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  const { data: { craft } = {} } = useQuery(GetCraftDocument, { variables: { craftId } });
  const [updateCraft] = useMutation(UpdateCraftDocument, { refetchQueries: [{ query: GetCraftDocument, variables: { craftId } }, { query: GetCraftsDocument, variables: { organizationId } }] }); 

  useEffect(() => {
    if(craft){
      setInitialValues({
        sharePlane: false,
        engineTimes: true,
        fuelStatus:true, 
        currentLocation: true, 
        planeAirworthiness: true,
        scheduleStatus: true,
        inspections: true,
      });
    }
    

  }, [craft]);


  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
        updateCraft({
            variables: {
              updateCraftInput:{
                id:craftId,
                
              }
            },
        })
        .then(closeFlyout)
        .catch(console.error);
    },
    [updateCraft, user]
  );

  return (
    <div className="p-4">
      {modal && <Modal cancel={setModal} accept={() => updateCraft({
            variables: {
              updateCraftInput:{
                id:craftId,
                organization: { disconnect: { } }
              }
            },
        })
        .then(()=> {
          closeFlyout();
          setCraftId(null);
          navigate('/crafts');
        })
        .catch(console.error)} message={"You are about remove "+ craft?.tailNumber +" from your Organization"}/> }
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values }) => (
          <Form>
            {/* AIRCRAFT INFORMATION */}
            <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
              <FullFormikCheckBox required={true}  name="homeBase" label="Homebase Airport *" />
              <FullFormikInput required={true}  name="tailNumber" label="Tail # *" />
              <FullFormikInput required={true}  name="year" label="Year *" />
              <FullFormikInput required={true}  name="make" label="Make *" />
              <FullFormikInput required={true} name="model" label="Model *" />
              <FullFormikInput name="fuelCapacity" label="Fuel Capacity *" />
              <FullFormikSelect name="fuelUnit" >
                <option value={VolumeMeasurement.Gallons}>Gallons</option>
                <option value={VolumeMeasurement.Liters}>Liters</option>
                <option value={VolumeMeasurement.Pounds}>Pounds</option>
                <option value={VolumeMeasurement.Quarts}>Quarts</option>
              </FullFormikSelect>
              <FullFormikSelect name="type" label="Aircraft Type" >
                <option value={CraftType.Singleengine}>Single Engine</option>
                <option value={CraftType.Multiengine}>Multi Engine</option>
              </FullFormikSelect>
              <FullFormikSelect name="loggingOption" label="Logging Type" >
                <option value={LogType.Logtach}>Tach Time</option>
                <option value={LogType.Loghobbs}>Hobbs Time</option>
                <option value={LogType.Logboth}>Tach + Hobbs</option>
              </FullFormikSelect>
              <FullFormikTextarea name="regAddress" label="Registration Address" placeholder='Aircraft Registration Address'  />
            </div>
            <div className="flex justify-end mt-2 w-full gap-5">
              <Button
                type="button"
                text="Save Changes"
                color="navy"
                size="xs"
                onClick={()=>handleSubmit(values)}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ShareAircraft;