import { useMutation, useQuery } from "@apollo/client";
import { ArrowDownIcon, TrashIcon } from "@heroicons/react/24/solid";
import Card from "components/Card/Card";
import { useSession } from "contexts";
import { GetOrganizationDocument, GetTransactionDocument, TransactionUpdateInput, TransactionsUpdateAttachmentsInput, UpdateTransactionAttachmentsDocument } from "graphql/generated";
import { useEffect, useState } from "react";
import { formatApiDateTime } from "utils/formatter";
import { webFormat } from "utils/statuses";

type TransactionFiles = File | { signedUrl?: string; name?:string; id?: string };

const ViewTransaction = function({ closeFlyout, id } : { closeFlyout: () => void; id: string;}) {
    const { organizationId } = useSession();
    const { data : { transaction } = { }, refetch } = useQuery(GetTransactionDocument, { variables: { id } });
    const { data: { organization } = { } } = useQuery( GetOrganizationDocument, { variables: { id: organizationId } });
    const [files, setFiles] = useState<TransactionFiles[]>([]);
    const [updateTransactionAttachments] = useMutation(UpdateTransactionAttachmentsDocument, { refetchQueries: [{ query: GetTransactionDocument, variables: { id } }] }); 
    const updateFiles = ( updatedFiles ) => {
        const transactionInput: TransactionsUpdateAttachmentsInput = {
            attachmentsDisconnect: transaction?.attachments?.filter((attach) => !updatedFiles.find((file) => attach.id === file?.id ) ).map((attach) => attach.id) ?? [],
        };
        updateTransactionAttachments({ variables: { attachments: transactionInput, id } }).then(
        async() => {
            if (updatedFiles.filter((file) => (file as any)?.size)) {
                const formData = new FormData();
                updatedFiles.filter((file) => (file as any)?.size).forEach((file) => {
                    formData.append('files', file as any);
                });
                formData.append('transactionId', id);
                const res = await fetch(process.env.REACT_APP_API_URL + '/attachments/upload', { method: 'POST', body: formData });
                refetch();
            }
        }
        ).catch(console.error);
    };
    useEffect(() => {
        setFiles( transaction?.attachments?.map((attach) => ({  id: attach.id, signedUrl: attach.signedUrl, name: attach.name })) ?? [] );
    }, [transaction]);
    return (
        <div>
            <Card className="p-10 mx-5 gap-3 flex flex-col overflow-x-auto">
                <div>
                    <h1 className="font-bold">PARTNERSHIP:</h1>
                    <h2 className="font-semibold text-2xl">{organization?.name}</h2>
                    <h2 className="">{organization?.billingEmail}</h2>
                </div>
                <div className="flex flex-col gap-1">
                    <h1 className="font-bold">TRANSACTION:</h1>
                    <h2 className="font-semibold truncate text-2xl">#{transaction?.id}</h2>
                    <h2 className="mt-2">Wallet: {transaction?.wallet?.id}</h2>
                    <h2 className="">Timestamp: {formatApiDateTime(transaction?.timestamp)}</h2>
                    <h2 className="">Status: <span className="bg-success text-white font-bold py-1 px-2 rounded">{webFormat(transaction?.status)}</span></h2>
                    <h2 className="">Description: {transaction?.inputData?.description}</h2>
                </div>
                <div className="flex flex-col">
                    <h1 className="font-bold text-xl">LINE ITEMS:</h1>
                    <table className="border-collapse border border-gray-300">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="border p-2">Quantity</th>
                                <th className="border p-2">Description</th>
                                <th className="border p-2">Unit Price</th>
                                <th className="border p-2">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="text-center">
                                <td className="border p-2">{(transaction?.inputData?.quantity ?? 0)?.toFixed(2)}</td>
                                <td className="border p-2">{transaction?.inputData?.description}</td>
                                <td className="border p-2">{transaction?.inputData?.rate}</td>
                                <td className="border p-2">{transaction?.isDeposit && '-'}${Number(transaction?.value).toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Card>
            <Card title='Transaction Attachments' className="p-10 mx-5">
                <div
                  className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6"
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                  onDrop={(e) => {
                    e.preventDefault();
                    let curFiles = files ?? [];
                    if (e.dataTransfer.items) {
                      // Use DataTransferItemList interface to access the file(s)
                      [...e.dataTransfer.items].forEach((item, i) => {
                        // If dropped items aren't files, reject them
                        if (item.kind === 'file') {
                          const file = item.getAsFile();
                          curFiles = [...curFiles, file];
                        }
                      });
                      setFiles(curFiles);
                    } else {
                      // Use DataTransfer interface to access the file(s)
                      [...e.dataTransfer.files].forEach((file, i) => {
                        curFiles = [...curFiles, file];
                      });
                      setFiles(curFiles);
                    }
                    updateFiles(curFiles);
                  }}>
                    <div className="space-y-1 text-center">
                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
                            <span>Upload a file</span>
                            <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                multiple
                                className="sr-only"
                                onChange={(e) => { 
                                    const curFiles = [...files, ...Array.from(e.target.files)];
                                    setFiles(curFiles);
                                    updateFiles(curFiles);
                                }}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                    </div>
                </div>
                <div className="text-xs text-indigo-800 py-2">
                {Array.from(files ?? [])
                    .map((file, index) => ( <div key={index} className='flex gap-3 my-2'>
                    <span className='text-lg'>{file.name}</span> 
                    
                    {(file as any).signedUrl && <a href={(file as any).signedUrl} className="font-medium text-indigo-600 hover:text-indigo-500" download>
                        <ArrowDownIcon
                            title="Download"
                            className="text-brand-500 bg-white w-12 h-6 p-0.5 border border-brand-200 rounded cursor-pointer hover:bg-brand-50 transition"
                        /> 
                    </a>}
                    <TrashIcon
                        onClick={() => {
                            const curFiles = files.filter((f) => f.name !== file.name);
                            setFiles(curFiles);
                            updateFiles(curFiles);
                        }}
                        title="Delete"
                        className="text-rose-500 bg-white w-12 h-6 p-0.5 border border-rose-200 rounded cursor-pointer hover:bg-rose-50 transition"
                    /> 
                    </div> ) )}
                </div>
            </Card>
        </div>
    );

};


export default ViewTransaction;